.bonus-product__cover {
  background-color: #a99e9e;
  height: 160px;
  width: 160px;
  margin: auto;

  .bonus-product__cover__title {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding-top: 35px;
    color: #fff;
  }

  .bonus-product__cover__tag {
    position: absolute;
    bottom: 6px;
    left: 0;
    right: 0;
    text-align: center;
    width: 60px;
    background-color: #ff5200;
    color: #fff;
    border-radius: 12px;
    margin: auto;
  }

  .bonus-product__image__item {
    width: 100%;
    height: 100%;
  }
}

.bonus-product__image__item {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  position: relative;
  width: 80px;
  height: 80px;
  background-color: #000;
  margin: 0 2px 2px 0;
  color: #fff;
}

.bonus-product__images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.bonus-product__image {
  >img {
    opacity: 1;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;

    &:hover {
      opacity: 0.6;
    }
  }
}

.bonus-product__image--delete {
  >img {
    opacity: 0.3;

    &:hover {
      opacity: 0.3;
    }
  }
}

.bonus-product__image__upload__btn {
  padding-top: 10px;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #a99e9e;
  color: #000;
}

.bonus-product__image__tools {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ff5200;
  background-color: #131616a3;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;

  > * {
    cursor: pointer;
  }
}

.bonus-product__quantity {
  border-bottom: 1px solid #eee;
  padding: 10px 3px 3px;
  cursor: pointer;
  min-width: 60px;
  text-align: right;
}

.recommend-product__status {
  color: #000;
  margin: 0 6px;
  border-bottom: 1px solid #d9d2d2;
  padding: 0 3px;
}

.add__recomend-product__title {
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  overflow: auto;
}

.user__bonus-product__product__name {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}