@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: translate(-50%, -50%) scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: translate(-50%, -50%) scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: translate(-50%, -50%) scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
  }
}
// 若有置中
.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}

@keyframes bounceIn2 {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale(0.3, 0.3);
  }

  20% {
    transform: scale(1.1, 1.1);
  }

  40% {
    transform: scale(0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale(1.03, 1.03);
  }

  80% {
    transform: scale(0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.bounceIn2 {
  animation-duration: 0.75s;
  animation-name: bounceIn2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-duration: 0.5s;
  animation-name: fadeIn;
}


.pagefadeIn {
  animation-duration: 1s;
  animation-name: fadeIn;
}



@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 0.5s;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
  animation-duration: 0.5s;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
  animation-duration: 0.3s;
}