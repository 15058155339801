@import '../../unit/function';
@import '../../unit/variable';

// 熱門精選 拿出來額外寫
.popular {
  .block{
    padding: 25px 35px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
    margin-bottom: 40px;
  }
  .sort {
    cursor: pointer;
    user-select: none;
    transition: .3s;
    &:hover{
      color: rgba(black, 0.5);
    }
  } 
  .top-block {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media screen and (max-width: $media-size-phone) {
      flex-direction: column;
      margin-bottom: 15px;
      button {
        margin-top: 15px;
      }
    }
    .text {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      color: $main-color;
    }
    button,a {
      border: none;
      font-size: 18px;
      color: white;
      text-align: center;
      border-radius: 4px;
      background-color: $color-blue;
      padding: 5px 10px;
      margin-left: 15px;
    }
  }
  .feature-information{
    width: 100%;
    box-shadow: 0px 0px 1px rgba(black, 0.2);
    padding: 15px;
    flex-wrap: wrap;
    .feature-title,.feature-description{
      display: flex;
      align-items: center;
      margin-top: 8px;
      label {
        width: 120px;
        font-size: 18px;
        font-weight: bold;
        color: $main-color;
        padding: 0px 15px;
        text-align: right;
      }
      input,textarea,select {
        border: none;
        outline: none;
        border: solid 1px #dddddd;
        border-radius: 3px;
        padding: 8px;
        font-size: 18px;
        font-weight: bold;
        color: #666666;
        width: calc(100% - 120px);
      }
      input,select {
        height: 35px;
      }
      select {
        padding: 0 8px;
        font-size: 16px;
      }
      label.check {
        display: inline-block;
        width: 60px;
        height: 30px;
        border-radius: 20px;
        background-color: $toggle-checkbox-bgc-off;
        cursor: pointer;
        transition: all .3s;
        &:after {
          content: '';
          height: 29px;
          width: 29px;
          border-radius: 50%;
          box-shadow: 0px 0px 5px rgba(black,0.5);
          background-color: white;
          position: absolute;
          top: 0px;
          left: 0px;
          transition: all .3s;
        }
      }
      input[type="checkbox"] {
        display: none;
        &:checked {
          + label {
            background-color: $toggle-checkbox-bgc-on;
            &:after{
              left: 100%;
              transform: translateX(-100%);
            }
          }
        }
      }
    }
    .edit-btn{
      margin: 8px 0px 0px;
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
  table {
    td,th{
      border: none;
    }
    .text-left {
      text-align: left;
    }
    td.line{
      color: $table-head-bgc;
      text-decoration:line-through;	
    }
  }
  .btn-group{
    margin-top: 20px;
    justify-content: flex-end;
  }
  .btn-trash {
    border: none;
    background-color: red;
    outline: none;
  }
}