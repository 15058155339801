@import '../unit/function';
@import '../unit/variable';

.login-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .background-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: contain;
  }

  .outer-container {
    border: 30px solid #A6AAB8;
    border-radius: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 684px;
    height: 440px;
    background-color: white;
    display: flex;
    align-items: center;
    @media screen and (max-width: $media-size-ipad) {
      border: none;
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(black, 0.2);
      width: 100%;
      height: auto;
      max-width: 320px;
      min-width: 320px;
    }
    .right-container {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: $media-size-ipad) {
        display: none;
      }
    }

    .left-container {
      width: 60%;
      padding: 0 35px;
      .top-header {
        height: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        .logo{
          height: 100%;
          width: 187px;
          img {
            width: 100%;
          }
        }
        .text {
          text-align: right;
          width: calc(100% - 187px);
          font-size: 24px;
          font-weight: bold;
          color: #4a4a4a;
        }

        .sub__text--highlight {
          font-size: 14px;
          font-weight: 500;
          background-color: red;
          color: #fff;
          border-radius: 6px;
          padding: 2px 3px;
          text-align: center;
        }
      }
      .input-box {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        border: 1px solid #DDDDDD;
        padding: 12px;
        color: #9B9B9B;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 20px;
        i {
          font-size: 19px;
          margin-right: 12px;
        }
        input {
          border: none;
          outline: none;
          color: #4a4a4a;
          font-size: 18px;
          margin-left: 12px;
          width: 100%;
        }
      }
      .tool-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 18px;
        @media screen and (max-width: $media-size-ipad) {
          margin-bottom: 40px;
        }
        .remember-me {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          label.rember-me {
            font-size: 16px;
            color: #989898;
            cursor: pointer;
            user-select: none;
          }
          input[type="checkbox"] {
            display: none;
            &:checked {
              ~.check-box::before {
                display: block;
              }
            }
          }
          label.check-box {
            width: 18px;
            height: 18px;
            border: 1px solid #EFF1F4;
            box-shadow: 1px 4px 5px rgba(#000000, 0.2);
            border-radius: 3px;
            margin-right: 8px;
            cursor: pointer;
            &:before {
              content: "✔";
              font-size: 20px;
              font-weight: bold;
              color: darken(#989898, 10);
              display: none;
              width: 100%;
              height: 100%;
              position: absolute;
              top: -8px;
            }
          }
        }
      }
      button.login-btn {
        border: none;
        outline: none;
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 15px;
        text-align: center;
        color: white;
        padding: 9px 0px;
        background-color: #4E546C;
        cursor: pointer;
        transition: .3s;
        &:hover {
          background-color: lighten(#4E546C, 8);
        }
        @media screen and (max-width: $media-size-ipad) {
         font-size: 20px;
        }
      }
      @media screen and (max-width: $media-size-ipad) {
        width: 100%;
        padding: 20px 15px;
      }
    }

    .sepetate {
      width: 2px;
      height: 330px;
      background-color: #C5C4C4;
      @media screen and (max-width: $media-size-ipad) {
        display: none;
      }
    }
  }
}