@import '../../unit/function';
@import '../../unit/variable';

.AddPopular-shadow{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba($main-color, 0.5);
  &.onVisible{
    display: flex;
  }
  .add-block {
    width: 700px;
    min-height: 400px;
    background-color: white;
    box-shadow: 0px 0px 2px black;
    border-radius: 15px;
    padding: 20px 40px;
    .btn-close {
      border: none;
      outline: none;
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: inherit;
      color: #333333;
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
    }
    .search-box{
      margin: 15px 0px;
      label {
        border: 1px solid $main-color;
        display: inline-block;
        width: 200px;
        height: 35px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        input {
          border: none;
          height: 100%;
          outline: none;
          padding-left: 5px;
          width: 130px;
        }
        img {
          width: 18px;
          height: 18px;
        }
        .close {
          visibility: hidden;
          opacity: 0;
          transition: .3s;
          cursor: pointer;
          &.open{
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
    .product-list-box{
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      .prodct-box{
        justify-content: left;
        &:nth-child(even){
          padding-left: 40px;
        }
        img {
          margin: 0px 15px;
        }
        .product-name{
          font-size: 18px;
          letter-spacing: 1px;
        }
        @media screen and (max-width: $media-size-phone){
          &:nth-child(even){
            padding-left: 0px;
          }
        }
      }
    }
    hr {
      height: 1px;
      background-color: #979797;
      margin: 0px -40px
    }
  }
}