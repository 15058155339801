@import './function';
@import './variable';
.btn-group {
  width: 100%;
  display: flex;
}

button.btn,a.btn {
  border: none;
  color: white;
  font-size: 18px;
  padding: 8px 35px;
  text-align: center;
  border-radius: 2px;
  outline: none;
  &.main-color {
    background-color: $main-color;
  }
  &.save {
    background-color: $color-blue;
  }
  &.delete {
    background-color: $color-red;
    margin-left: 15px;
  }
  &.cancel{
    background-color: #BEBEBE;
    margin-left: 15px;
  }
}