
.qrcord-card {
  width: 400px;
  background-color: #fff;
  position: absolute;
  z-index: 999;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 20px 8px 20px;
  .card-inner {
    width: 375px;
    height: 400px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #4a4a4a;
    .card-name, .card-jobType {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 4px;
      position: absolute;
    }
    .card-name {
      top: 105px;
      left: 160px;
    }
    .card-jobType {
      top: 157px;
      left: 160px;
    }
    .u-qrcode {
      margin-top: 8px;
    }
  }
}