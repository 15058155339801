@import '../../unit/function';
@import '../../unit/variable';

.member-managemenet {
  width: 100%;
  margin-bottom: 15px;
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
  }
  .member {
    font-size: 18px;
    margin-bottom: 10px;
    color: $main-color;
    display: flex;
    justify-content: space-between;
  }
  .sort {
    cursor: pointer;
    user-select: none;
    transition: .3s;
    &:hover{
      color: rgba(black, 0.5);
    }
  } 
  .search-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
    .text {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.5px;
    }
    .search-input {
      border: 1px solid rgba(black, 0.2);
      padding: 2px 8px;
      border-radius: 5px;
      overflow: hidden;
      input {
        outline: none;
        border: none;
        margin: 0;
      }
      img {
        cursor: pointer;
      }
    }
    .date-input {
      border: 1px solid rgba(black, 0.2);
      padding: 2px 8px;
      border-radius: 5px;
      input {
        outline: none;
        border: none;
        margin: 0;
      }
      img {
        cursor: pointer;
      }
    }
  }
  table {
    tbody {
      tr > td > a {
        border: 1px solid;
        display: inline-block;
        width: 70px;
        height: 30px;
        background-color: $main-color;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        line-height: 30px;
        transition: background-color .3s;
        &:hover {
          background-color: lighten($main-color,3);
        }
      }
    }
  }
}