@import '../../unit/function';
@import '../../unit/variable';

.exam-type {
  width: 100%;
  margin-bottom: 15px;
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
  }
  .top-block {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    @media screen and (max-width: $media-size-phone) {
      flex-direction: column;
      margin-bottom: 15px;
      button {
        margin-top: 15px;
      }
    }
    .text {
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 1px;
      color: $main-color;
    }
    button {
      border: none;
      font-size: 18px;
      color: white;
      text-align: center;
      border-radius: 4px;
      background-color: $color-blue;
      padding: 5px 10px;
      margin-left: 15px;
    }
  }
  hr {
    height: 1px;
    background-color: #9B9B9B;
    margin: 0;
  }
  .type-box {
    overflow: auto;
    ul.type-list {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #9B9B9B;
        padding: 10px 15px;
        .right, .left {
          display: flex;
          align-items: center;
        }
        .left {
          max-height: 60px;
          .type-name {
            font-size: 20px;
            font-weight: 600;
            color: #4A4A4A;
            opacity: 0;
            visibility: hidden;
            width: 0;
            &.show{
              min-width: 120px;
              opacity: 1;
              visibility: visible;
            }
            @media screen and (max-width: $media-size-ipad) {
              width: 0;
              visibility: hidden;
              &.show{
                min-width: 80px;
                font-size: 16px;
                opacity: 1;
                visibility: visible;
              }
            }
          }
          .rename-btn {
            background-color: inherit;
            font-size: 14px;
            color: #979797;
            padding: 4px 8px;
            border: 1px solid #979797;
            border-radius: 20px;
            white-space: nowrap;
          }
          input.rename-input {
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            color: #4A4A4A;
            border: 1px solid #979797;
            border-radius: 10px;
            padding: 0px 15px;
            outline: none;
            transition: .3s;
            width: 0px;
            visibility: hidden;
            opacity: 0;
            &.show {
              width: 125px;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        .right{
          .notice {
            font-size: 16px;
            font-weight: 600;
            color: #BFBFBF;
            @media screen and (max-width: 650px) {
              display: none;
            }
          }
          .status-box {
            margin: 0 10px;
          }
          .btn.delete {
            font-size: 14px;
            border-radius: 20px;
            width: 50px;
            height: 25px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.status-box > label {
  display: inline-block;
  width: 60px;
  height: 30px;
  border-radius: 20px;
  background-color: $toggle-checkbox-bgc-off;
  cursor: pointer;
  transition: all .3s;
  &:after {
    content: '';
    height: 29px;
    width: 29px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(black,0.5);
    background-color: white;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all .3s;
  }
}
.status-box> input[type="checkbox"] {
  display: none;
  &:checked {
    + label {
      background-color: $toggle-checkbox-bgc-on;
      &:after{
        left: 100%;
        transform: translateX(-100%);
      }
    }
  }
}