.alert-box {
  min-width: 310px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 3px #696969;
  background-color: #F7F7F7;
  .top{
    width: 100%;
    min-height: 20px;
    .cross{
      height: 20px;
      width: 20px;
      position: absolute;
      top: 0px;
      right: 0px;
      cursor: pointer;
      &:before, &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 1px;
        background-color: #ACACAC;
        top: 15px;
        right: 10px;
      }
      &:before {
        transform: rotate(55deg);
      }
      &:after {
        transform: rotate(-55deg);
      }
    }
  }
  .contnet{
    text-align: center;
    font-weight: 600;
    letter-spacing: 2px;
    padding: 15px 70px 35px;
    font-size: 18px;
  }
  .combtns{
    display: flex;
    .ok,.cancel{
      text-align: center;
      width: 100%;
      padding: 10px 30px;
      font-size: 18px;
      cursor: pointer;
    }
    .ok{
      background-color: #A43D3D;
      color: white;
    }
  }
}