@import '../../unit/function';
@import '../../unit/variable';

.sale-exam-management {
  width: 100%;
  margin-bottom: 15px;
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
  }
  .specialOffer {
    color: #D0021B;
  }
  .search-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 8px;
    .text {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.5px;
    }
    .search-input {
      border: 1px solid rgba(black, 0.2);
      padding: 2px 8px;
      border-radius: 5px;
      overflow: hidden;
      input {
        outline: none;
        border: none;
        margin: 0;
      }
      img {
        cursor: pointer;
      }
    }
  }
  .sort {
    cursor: pointer;
    user-select: none;
    transition: .3s;
    &:hover{
      color: rgba(black, 0.5);
      font-weight: 600;
    }
  } 
}