@import '../../unit/function';
@import '../../unit/variable';

.main-home {
  width: 100%;
  margin-bottom: 15px;
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    hr {
      width: 100%;
      margin: 0;
    }
    .information-top,.information-bottom {
      width: 100%;
      justify-content: space-around;
      .info-block{
        cursor: pointer;
        transition: .3s;
        width: 290px;
        margin: 18px 0px;
        .image-box {
          width: calc(100% - 190px);
          max-width: 100px;
        }
        .content {
          width: 190px;
          height: 100%;
          padding: 15px 30px;
          .title {
            font-size: 18px;
            font-weight: 600;
            color: $main-color;
            text-align: left;
          }
          .name {
            font-size: 18px;
            font-weight: bold;
            color: $color-blue;
            margin-top: 8px;
          }
          .number {
            font-size: 16px;
            font-weight: bold;
            color: $main-color;
            text-align: left;
            margin-top: 20px;
          }
        }
        &:hover {
          box-shadow: 0px 0px 200px rgba(black, 0.1);
          transform: translate(-1px, -1px);
        }
        @media screen and (max-width: $media-size-phone) {
          flex-direction: column;
          .image-box, .content{
            width: 100%;
          }
          .content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          } 
        }
      }
    }
    .information-bottom {
      margin-top: 15px;
      .info-block {
        .content{
          width: 260px;
        }
      }
    }
  }
}