@import '../../unit/function';
@import '../../unit/variable';

.check-exam-history {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black,0.2);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .table-responsive {
    border: 1px solid #cccccc;
    height: auto;
    max-height: 600px;
  }
  .box {
    width: 500px;
    min-height: 250px;
    padding: 18px 25px;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(black,0.5);
  } 
  .close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
    z-index: 2;
  }
  .title {
    font-size: 22px;
    font-weight: bold;
    color: $main-color;
    padding: 0px 16px 8px;
  }
}
