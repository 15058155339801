@import './function';
@import './variable';

.table-responsive {
  border: 1px solid #cccccc;
  height: 620px;
}
table {
  color: $main-color;
  font-size: 18px;
  white-space: nowrap;
  thead {
    background-color: $table-head-bgc;
    color: white;
    th {
      text-align: center;
      font-weight: normal;
    }
  }
  tbody {
    tr > td {
      text-align: center;

    }
    tr > td > button:not(.MuiButton-root) {
      border: 1px solid;
      display: inline-block;
      width: 70px;
      height: 30px;
      background-color: $main-color;
      color: white;
      border-radius: 5px;
      text-decoration: none;
      line-height: 30px;
      transition: background-color .3s;
      &.danger {
        background-color: #ff4444;
      }
      &.warning {
        background-color: #FFA500;
      }
      &.primary {
        background-color: $color-blue;
      }
      &.success {
        background-color: $color-green;
      }
      &:hover {
        background-color: lighten($main-color,3);
        &.danger {
          background-color: lighten(#ff4444,3);
        }
      }
    }
    tr > td > button:not(.MuiButtonBase-root.Mui-disabled) {
      &:disabled {
        border: 1px solid;
        display: inline-block;
        width: 70px;
        height: 30px;
        background-color: $main-color;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        line-height: 30px;
        transition: background-color .3s;
        &.danger {
          background-color: #ff4444;
        }
        &.warning {
          background-color: #FFA500;
        }
        &.primary {
          background-color: $color-blue;
        }
        &.success {
          background-color: $toggle-checkbox-bgc-on-disable;
        }
        &:hover {
          background-color: lighten($main-color,3);
          &.danger {
            background-color: lighten(#ff4444,3);
          }
        }
      }
    }
    tr > td > a {
      border: 1px solid;
      display: inline-block;
      width: 70px;
      height: 30px;
      background-color: $main-color;
      color: white;
      border-radius: 5px;
      text-decoration: none;
      line-height: 30px;
      transition: background-color .3s;
      &.danger {
        background-color: #ff4444;
      }
      &.warning {
        background-color: #FFA500;
      }
      &.primary {
        background-color: $color-blue;
      }
      &.success {
        background-color: $color-green;
      }
      &.full {
        width: 100%;
      }
      &:hover {
        background-color: lighten($main-color,3);
        &.danger {
          background-color: lighten(#ff4444,3);
        }
      }
    }
    /* switch 使用，與 Material-UI 有衝突故先移除
    tr > td > label {
      display: inline-block;
      width: 60px;
      height: 30px;
      border-radius: 20px;
      background-color: $toggle-checkbox-bgc-off;
      cursor: pointer;
      transition: all .3s;
      &:after {
        content: '';
        height: 29px;
        width: 29px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px rgba(black,0.5);
        background-color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: all .3s;
      }
    }
*/
    tr > td > input[type="checkbox"] {
      display: none;
      &:disabled {
        + label {
          background-color: $toggle-checkbox-bgc-off-disable;

        }
      }
    }

    tr > td > input[type="checkbox"] {
      display: none;
      &:disabled {
        &:checked{
          + label {
            background-color: $toggle-checkbox-bgc-on-disable;
            &:after{
              left: 100%;
              transform: translateX(-100%);
            }
          }
        }
      }
    }

    tr > td > input[type="checkbox"] {
      display: none;
      &:checked {
        + label {
          background-color: $toggle-checkbox-bgc-on;
          &:after{
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }
    }

  }
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: white;
}
.table-striped > tbody > tr:nth-of-type(even) {
  background-color: $table-row-even-bgc;
}
.pagination-box{
  margin: 8px 0px 8px 0px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $media-size-phone) {
    justify-content: center;
  }
  .view-single-page{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $main-color;
    @media screen and (max-width: $media-size-phone) {
      display: none;
    }
  }
  nav{
    .pagination {
      padding: 0;
      margin: 0;
    }
  }
}