@import '../unit/function';
@import '../unit/variable';

.how-use {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 32px;
  z-index: 9;
  i {
    cursor: pointer;
  }
  .how-use-img {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 999;
    background-color: rgba(black,0.2);
    display: flex;
    justify-content: center;
    overflow: auto;
    .img-box {
      margin: 80px 0px;
      max-width: 700px;
      img {
        width: 100%;
      }
      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        color: white;
        font-size: 26px;
        z-index: 99;
      }
    }
  }
}