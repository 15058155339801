.vjs-button>.vjs-icon-placeholder:before {
  line-height: 0;
}

.video-js .vjs-big-play-button {
  line-height: 0;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  left: -15px;
}