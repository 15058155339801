$logo-bgc: #555C78;
$main-color: #4E546C;
$main-bgc: #EEF4F9;

$table-head-bgc: #9B9B9B;
$table-row-even-bgc: #F4F4F4;

$toggle-checkbox-bgc-on: #4CD964;
$toggle-checkbox-bgc-on-disable: #aed8ba;
$toggle-checkbox-bgc-off: #D1CFCF;
$toggle-checkbox-bgc-off-disable: #eeecec;

$slide-bar-font-color: #A4B7C8;
$slide-bar-font-color-active: #717171;

$media-size-ipad: 768px;
$media-size-phone: 425px;

$box-shadow-color: #7797B2;

$color-blue: #2A6FBD;
$color-red: #D0021B;
$color-dark-green: #06453F;
$color-green: #5CB85C;
$color-orange: orange;




