@import '../../unit/function';
@import '../../unit/variable';

.peiyou-setting {
  width: 100%;
  margin-bottom: 15px;
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 1px #7797B2;
    min-height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    @media screen and (max-width: $media-size-ipad){
      justify-content: flex-start;
    }
  }
  .bar-image-box {
    min-width: 740px;
    width: 740px;
    .bar-image{
      width: 100%;
      height: 557px;
      display: flex;
      .exam-block {
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: $main-color;
          position: absolute;
          top: -40px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
        }
        .content {
          overflow: auto;
          width: 100%;
          height: 100%;
          .background {
            width: 100%;
            border-bottom: 1px solid white;
            background-position: center;
            background-clip: content-box;
          }
        }
        width: 138px;
        height: 146px;
        position: absolute;
        top: 225px;
        &.makeup {
          left: 135px;
        }
        &.target {
          left: 50%;
          transform: translateX(-52%); 
        }
        &.paiyou {
          right: 140px;
        }
      }
    }
    .btn-group {
      justify-content: center;
    }
  }
  .search-block {
    background-color: #424242;
    margin-top: 15px;
    padding: 15px 80px;
    @media screen and (max-width: $media-size-ipad){
      padding: 15px 15px;
    }
    .search-top {
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
      flex-wrap: wrap;
      .filter-tags {
        display: flex;
        align-items: center;
        @media screen and (max-width: $media-size-ipad){
          flex-wrap: wrap;
        }
        @media screen and (max-width: $media-size-phone){
          .filter-tag {
            width: 100%;
            &.input-box {
              width: 100% !important;
            }
          }
        }
        .filter-tag {
          height: 33px;
          font-size: 16px;
          color: #4A4A4A;
          padding: 4px 8px;
          background-color: white;
          border: 1px solid #9E9D9D;
          cursor: pointer;
          &.active {
            background-color: $main-color;
            color: white;
          }
          &.input-box {
            border-radius: 0;
            width: 110px;
            margin: 0;
            input {
              width: 100%;
              font-size: 18px;
            }
          }
        }
        
      }
      .input-box {
        width: 240px;
        height: 33px;
        background-color: white;
        display: flex;
        border-radius: 3px;
        overflow: hidden;
        @media screen and (max-width: 1024px){
          width: 100%;
          margin-top: 8px;
        }
        .search-icon {
          width: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        input {
          width: calc(100% - 45px);
          border: none;
          outline: none;
          padding: 0px 15px;
        }
      }

    }
    .search-result {
     display: flex;
     height: 170px;
     border: 1px solid #9E9D9D;
     overflow: auto;
     .background {
       margin: 0 6px;
       min-width: 128px;
       width: 128px;
       cursor: pointer;
     }


    }
  }
  .background {
    height: 146px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    user-select: none;
    .cross {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
      z-index: 3;
    }
    .title,.type {
      width: 103px;
      color: #4A4A4A;
      text-align: center;
    }
    .title {
      font-size: 14px;
      margin-top: 28px;
    }
    .type {
      font-size: 12px;
      margin-top: auto;
      margin-bottom: 33px;
    }
  }
}