@import '../unit/function';
@import '../unit/variable';

.tools .account {
  .account-information {
    position: fixed;
    width: 250px;
    top: 80px;
    right: -250px;
    padding: 8px 0px;
    background-color: $main-color;
    border-radius: 3px;
    box-shadow: 0px 0px 5px rgba(black,0.2);
    display: flex;
    flex-wrap: wrap;
    transition: .3s;
    z-index: 999;
    &.active {
      right: 5px;
    }
    .admin-name {
      width: 100%;
      font-size: 22px;
      font-weight: bold;
      letter-spacing: 2px;
      padding: 0px 16px;
      color: $slide-bar-font-color;
    }
    .notification-box {
      width: 100%;
      max-height: 500px;
      margin-top: 8px;
      background-color: lighten($main-color, 10);
      overflow: auto;
      transition: .3s;
      a {
        display: inline-block;
        padding: 8px 16px;
        width: 100%;
        font-size: 18px;
        color: $slide-bar-font-color;
        transition: .3s;
        &:hover {
          background-color: lighten($main-color, 5);
          // border: 1px solid $slide-bar-font-color;
        }
      }
    }
    .btn-group {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      padding: 0px 16px;
      button.btn {
        width: 100%;
        padding: 5px 10px;
        margin: 0;
      }
    }
  }
}