.bonus {
  text-align: center;
  padding-top: 36px;

  &__logo {
    img {
      text-align: center;
      height: 20vh;
    }
  }

  &__logo-btn {
    margin: 12px auto;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin: 24px auto;
    letter-spacing: 1px;
    color: #db554e;
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 6px;

    svg {
      vertical-align: middle;
      margin-right: 6px;
    }
  }

  &__btns {
    > * {
      display: flex;
      margin: 12px auto 24px;
    }
  }

  &__guset-code {
    margin: 12px 0;
    background-color: #fff;
    width: 100%;
    padding: 12px;
    border-radius: 3px;
  }

  &__guset-qrcode-reader {
    width: 300px;
    height: 300px;
    margin: 12px auto;
    background-color: #fff;
    border: 2px solid #eee;
    border-radius: 5px;
  }

  &__guest-amount {
    background-color: #fff;
    padding: 12px;

    > div > * {
      margin: 20px auto;
    }
  }

  &__guest-title {
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;

    &--warning {
      color: #ff5757;
    }
  }

  &__point {
    font-size: 3rem;
    font-weight: 600;
    color: #db554e;
    letter-spacing: 1px;
    margin: 12px auto;
  }

  &__cafe-shift-login {
    display: flex;
  }

  &__info {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;

    span {
      font-size: 20px;
      font-weight: 600;
      margin: auto 12px;
      color: #db554e;
    }
  }

  &__member-level-card {
    width: 100%;
    margin: auto;
  }

  &__member-level-image {
    height: 135px;
    background-position-y: top;
    background-size: cover;
  }

  &__member-level-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 12px;
  }

  &__member-contact {
    font-size: 1.2rem;
    color: #000;
    font-weight: 600;
    letter-spacing: 1px;
  }

  &__member-levle-info {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;
    margin: 6px auto 0;

    &__item {
      font-size: 12px;
      color: #7a8161;
      font-weight: 600;
    }
  }

  &__member-point {
    font-size: 2rem;
    font-weight: 600;
    color: #000;
    letter-spacing: 1px;

    &-unit {
      margin: auto 6px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-color: #000;
      color: gold;
      border-radius: 99px;
      font-size: 0.8rem;
      text-align: center;
    }
  }

  &__use-title {
    margin: 6px;
    text-align: right;
    color: #000;
    font-size: 1rem;
    letter-spacing: 1px;
  }

  &__use-detail {
    margin: 6px 24px 6px 6px;
    text-align: right;
    font-weight: 600;
    font-size: 1.2rem;
    position: relative;

    &--outside {
      position: absolute;
      bottom: 4px;
      right: -26px;
    }
  }

  &__use-bonus {
    font-size: 36px;
    font-weight: 600;
    color: #db554e;
    letter-spacing: 1px;
  }

  &__use {
    margin: auto;
    width: 100%;
  }

  &__reward {
    padding: 24px;
  }

  &__table-point {
    font-size: '1.5rem';
    font-weight: 600;
    color: green;
  }

  &__table-point--use {
    color: red;
  }

  &__view {
    margin: 24px;
    width: 100%;
    max-width: 350px;
  }

  &__close-view {
    text-align: right;
    margin-top: -12px;

  }

  &__avatar {
    &--use {
      background-color: red;
    }

    &--reward {
      background-color: green;
    }

    &--error {
      background-color: red;
    }

    &--normal {
      background-color: green;
    }

    &--solved {
      background-color: #3a63b9;
    }
  }

  &__comment {
    line-height: 26px;
    background-color: #eee;
    border-radius: 6px;
    padding: 6px;
    margin: 12px auto;
  }

  &__comment--error {
    background-color: #df4545;
    color: #fff;
  }

  &__comment--audit {
    background-color: #3a63b9;
    color: #fff;
  }

  &__service-charge {
    margin: 12px auto;

    &__title {
      font-size: 1.4rem;
      font-weight: 600;
    }

    &--delete {
      text-decoration: line-through;
    }
  }

  &__total-amount {
    font-size: 1.4rem;
    color: #db554e;
    font-weight: 600;
    margin: 12px auto;
  }

  &__audit-count {
    min-width: 130px;
    font-size: 2.5rem;
    font-weight: 600;
    color: #4a4a4a;
    border-radius: 4px;
    text-align: center;
    padding: 6px 0 12px;

    &__text {
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }

  &__audit-count--normal {
    background-color: #7a8161;
    color: #fff;
  }

  &__audit-count--abnormal {
    background-color: #df4545;
    color: #fff;
  }

  &__product__barcode {
    margin: 12px 0;
    font-size: 1.2rem;
    font-weight: 600;
    border: 2px solid #d7cece;
    border-radius: 6px;
    padding: 6px;
    color: #9c9c9c;
  }
}

.bonus__list__totals {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
  align-items: center;

  .bonus__list__total {
    padding: 3px 12px;
    margin: 0 0 6px 24px;

    &-number {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: right;
    }

    &-title {
      font-size: 0.7rem;
      font-weight: 500;
      text-align: left;
    }
  }

  .bonus__list__total--reward {
    color: #7a8161;
  }

  .bonus__list__total--used {
    color: #db554e;
  }
}