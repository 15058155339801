@import '../../unit/function';
@import '../../unit/variable';

.adventisting {
  .block{
    padding: 25px 35px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
    margin-bottom: 40px;
  }
  .tag-group{
    display: flex;
    .tag{
      width: 140px;
      height: 45px;
      box-shadow: 0px 0px 1px $box-shadow-color;
      background-color: white;
      color: $color-blue;
      font-size: 19px;
      font-weight: 500;
      text-align: center;
      line-height: 45px;
      cursor: pointer;
      transition: .3s;
      &.active {
        background-color: $color-blue;
        color: white;
      }
    }
    .tag.add-ad-btn{
      width: 70px;
      border: none;
      box-shadow: none;
      background-color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      .add-circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $color-blue;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .ad-edit-block {
    width: 100%;
  }
  [id^="uploadInput"],
  #uploadInput {
    display: none;
  }
  .image-block {
    width: 100%;
    height: 200px;

    background-color: #ddd;
    border-radius: 9px;
    border: 1px solid rgba(151, 151, 151, 0.22);
    overflow: hidden;
    .image {
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      cursor: pointer;

    }

    .delete-btn {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: white;
      color: $color-red;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;

      z-index: 99;
    }

    .upload-btn {
      font-size: 19px;
      color: #4A4A4A;
      font-weight: bold;
      letter-spacing: 2px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }


  }
  .notice {
    width: 100%;
    text-align: center;
    color: #999999;
    font-size: 16px;
    margin-top: 10px;
  }
  .form-groups{
    margin: 35px 0px;
    > div {
      display: flex;
      align-items: center;
      margin: 25px 0px;
    }
    label, h3 {
      margin: 0;
      display: inline-block;
      font-size: 18px;
      color: #4A4A4A;
      width: 75px;
      min-width: 75px;
      font-weight: 600;
    }
    input {
      border: 1px solid #979797;
      border-radius: 6px;
      width: calc(100% - 75px);
      height: 40px;
      margin-left: 15px;
      padding: 0px 15px;
      outline: none;
    }
    .date-picker {
      > div {
        width: calc(100% - 75px);
        .react-datepicker-wrapper {
          width: 100%;
        }
        .react-datepicker__input-container{
          width: 100%;
          input {
            width: calc(100% - 15px);
          }
        }
      }
    }
    .is-display > label {
      margin-left: 15px;
      display: inline-block;
      width: 60px;
      height: 30px;
      border-radius: 20px;
      background-color: $toggle-checkbox-bgc-off;
      cursor: pointer;
      transition: all .3s;
      &:after {
        content: '';
        height: 29px;
        width: 29px;
        border-radius: 50%;
        box-shadow: 0px 0px 5px rgba(black,0.5);
        background-color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        transition: all .3s;
      }
    }
    .is-display> input[type="checkbox"] {
      display: none;
      &:checked {
        + label {
          background-color: $toggle-checkbox-bgc-on;
          &:after{
            left: 100%;
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}