@import '../unit/function';
@import '../unit/variable';

.slidebar{
  width: 100%;
  height: 100%;
  min-height: 100%;
  padding: 22px 0px 0px;
  background-color: $main-color;
  overflow: auto;
  .closed {
    display: none;
    position: absolute;
    z-index: 99;
    top: 15px;
    right: 20px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    &:before,&:after {
      content: '';
      width: 30px;
      height: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $slide-bar-font-color;
    }
    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    @media screen and (max-width: $media-size-ipad) {
      display: block;
    }
  }
  .nav-bar {
    margin: 0;
    padding: 0;
    [class^="child-block"] {
      padding: 0;
      background-color: lighten($main-color, 5);
      margin-left: -38px;
      overflow: hidden;
      transition: .3s;
      height: 0;
      li {
        padding-left: 38px;
        a {
          font-size: 22px;
          padding: 15px 15px 15px 0px;
        }
      }
    }
    li {
      list-style-type: none;
      text-align: left;
      padding-left: 38px;
      a {
        display: inline-block;
        text-decoration: none;
        font-size: 25px;
        font-weight: 600;
        white-space: nowrap;
        color: $slide-bar-font-color;
        padding: 22px 22px 22px 0px;
        transition: .3s;
        opacity: 1;
        text-align: left;
        &:hover,&.active{
          color: $slide-bar-font-color-active;
        }
      }
      &.ipad-home{
        display: none;
        @media screen and (max-width: $media-size-ipad) {
          display: block;
        }
      }
    }
  }
  
}