.confirm-code {
  &__item {
    margin: 20px auto;
  }

  &__phone {
    font-size: 1.4rem;
    font-weight: 600;
    color: #4a4a4a;
  }

  &__code {
    font-size: 1.6rem;
    font-weight: 600;
    color: red;
  }

  &__title--small {
    font-size: 16px;
    font-weight: 500;
    margin: auto 12px auto auto;
    color: #4a4a4a;
  }

  &__empty {
    margin: 36px auto;
    font-size: 3rem;
    font-weight: 600;
  }
}