@import '../unit/function';
@import '../unit/variable';

.footer {
  margin: 0 -15px;
  font-size: 12px;
  flex-wrap: 600;
  padding: 15px 10px;
  text-align: center;
  color: darken($slide-bar-font-color,10);
}