@import '../unit/function';
@import '../unit/variable';


.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @keyframes ball-scale-multiple {
    0% {
      transform: translate(-50%, -50%) scale(0);
      opacity: 0; 
    }
    5% {
      opacity: 1; 
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0; 
    } 
  }
  
  .ball-scale-multiple {
    position: relative;
    transform: translateY(-30px); 
  }
  .ball-scale-multiple > div:nth-child(2) {
    animation-delay: -0.4s;
  }
  .ball-scale-multiple > div:nth-child(3) {
    animation-delay: -0.2s; 
  }
  .ball-scale-multiple > div {
    background-color: skyblue;
    border-radius: 100%;
    animation-fill-mode: both;
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(-50%, -50%);
    opacity: 0;
    margin: 0;
    width: 200px;
    height: 200px;
    animation: ball-scale-multiple 1s 0s linear infinite; 
  }
}