@import './unit/bootstrap';
@import './unit/table';
@import './unit/function';
@import './unit/variable';
@import './unit/layout';
@import './unit/buttons';
@import './unit/animation';
@import './font/fontawsome/fontawesome-all.min.css';
@import '~react-datepicker/dist/react-datepicker.css';

body,html {
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
  position: relative;
  font-family: PingFangSC-Regular, '微軟正黑體', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  width: 100%;
  height: 100%;

  .container{
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 75px;
    @media screen and (max-width: $media-size-ipad) {
      padding-top: px-to-vw(70px,768px);
    }
    @media screen and (max-width: 492px) {
      padding-top: 45px;
    }
    #toggle-menu {
      display: none;
      &:checked {
        ~ .sidebar-box {
          left: 0px;
        }
      }
    }
    .sidebar-box{
      width: 223px;
      transition: all 1s;
      @media screen and (max-width: $media-size-ipad) {
        position: absolute;
        left: -223px;
        top: 0px;
        z-index: 999;
        height: 100%;
      }
      &.not-Login {
        width: 0;
        opacity: 0;
        overflow: hidden;
        .nav-bar li a {
          font-size: 0;
          opacity: 0;
          margin: 0;
        }
      }
    }
    .main{
      display: flex;
      flex-direction: column;

      width: calc(100% - 223px);
      height: 100%;
      padding: 0px 15px;
      overflow-y: auto;

      transition: 1s;

      background-color: $main-bgc;
      @media screen and (max-width: $media-size-ipad) {
        width: 100%;
      }
      .footer {
        margin-top: auto;
      }
    }
  }
}

.swal2-container {
  z-index: 50000 !important;
}