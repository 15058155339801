@import '../../unit/function';
@import '../../unit/variable';

.set-exam {
  width: 100%;
  margin-bottom: 15px;
  .tag-group{
    display: flex;
    .tag{
      width: 140px;
      height: 45px;
      box-shadow: 0px 0px 1px $box-shadow-color;
      background-color: white;
      color: #4A4A4A;
      font-size: 19px;
      font-weight: 500;
      text-align: center;
      line-height: 45px;
      cursor: pointer;
      transition: .3s;
      &.active {
        background-color: $color-blue;
        color: white;
      }
    }
    .how-use {
      top: 0px;
    }
  }
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 1px $box-shadow-color;
  }
  .block-edit-exam, .block-set-bg {
    display: none;
    &.show {
      display: block;
    }
  }
}

