@import "../unit/function";
@import "../unit/variable";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 75px;
  background-color: white;
  display: flex;
  box-shadow: 0px 0px 5px rgba(black, 0.2);
  @media screen and (max-width: $media-size-ipad) {
    width: 100%;
    height: px-to-vw(70px, 768px);
    min-height: 45px;
  }
  .logo {
    width: 223px;
    background-color: $logo-bgc;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $media-size-ipad) {
      display: none;
    }
  }
  .version {
    color: #83505c;
    font-size: 12px;
    width: 60px;
    background-color: #fff6f6;
    border-radius: 3px;
    text-align: center;
    padding: 2px 0;
  }
  .version--highlight {
    background-color: #000;
    color: #fff;
  }
  .information {
    width: calc(100% - 223px);
    background-color: white;
    display: flex;
    @media screen and (max-width: $media-size-ipad) {
      width: 100%;
    }
    .hambarger {
      display: none;
      width: 75px;
      height: 100%;
      cursor: pointer;
      @media screen and (max-width: $media-size-ipad) {
        display: block;
        height: px-to-vw(70px, 768px);
        width: px-to-vw(70px, 768px);
        min-height: 45px;
        min-width: 45px;
      }
      .bar,
      &:before,
      &:after {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 5px;
        background-color: $main-color;
        @media screen and (max-width: $media-size-ipad) {
          width: px-to-vw(40px, 768px);
          height: px-to-vw(5px, 768px);
          min-height: 3px;
          min-width: 30px;
        }
      }
      .bar {
        top: 50%;
      }
      &:after {
        content: "";
        top: 30%;
      }
      &:before {
        content: "";
        top: 70%;
      }
    }
    .title {
      height: 100%;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 5px;
      color: $main-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      @media screen and (max-width: $media-size-ipad) {
        font-size: px-to-vw(36px, 768px);
        padding: 0px 5px;
      }
    }
    .tools {
      display: flex;
      align-items: center;
      margin-left: auto;
      .search,
      .ring,
      .videoUpload,
      .account {
        width: 30px;
        height: 30px;
        margin: auto 15px;
        cursor: pointer;
        img {
          width: 100%;
        }
        > * > svg {
          width: 30px;
          height: 30px;
          color: #8ca0b3;
        }
      }
      .ring .notification-dot {
        position: absolute;
        top: -7px;
        right: -6px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #2dd1ac;
        color: white;
        text-align: center;
        line-height: 20px;
        font-weight: bold;
      }
    }
  }
}
