@import '../unit/function';
@import '../unit/variable';

.page-404 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-block {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
  }
  .title {
    font-size: 26px;
    letter-spacing: 2px;
    line-height: 40px;
    font-weight: bold;
    color: $main-color;
    text-align: center;
  }
  a {
    margin-top: 40px;
  }
}