@import '../../unit/function';
@import '../../unit/variable';

.add-secret {
  .block{
    padding: 12px 20px;
    background-color: white;
    box-shadow: 0px 0px 4px #7797B2;
    min-height: 480px;
    margin-bottom: 40px;
  }
  .tag-group{
    display: flex;
    .tag{
      width: 140px;
      height: 45px;
      box-shadow: 0px 0px 1px $box-shadow-color;
      background-color: white;
      color: #4A4A4A;
      font-size: 19px;
      font-weight: 500;
      text-align: center;
      line-height: 45px;
      cursor: pointer;
      transition: .3s;
      &.active {
        background-color: $color-blue;
        color: white;
      }
    }
  }
  .title {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #4A4A4A;
    margin-bottom: 15px;
    button.save {
      padding: 4px 8px;
      font-weight: normal;
      letter-spacing: 2px;
      margin-left: 8px;
    }
  }
  .form-groups{
    .form-left {
      flex-wrap: wrap;
      .notice {
        width: 100%;
        font-size: 16px;
        line-height: 18px;
        color: #999999;
        text-align: right;
      }
      .switch-score-input {
        width: 100%;
        margin-bottom: 12px;
        .input-block {
          box-shadow: 0px 0px 1px rgba(black, 0.4);
          overflow: hidden;

        }
        .score-input-box {
          padding: 8px 16px;
        }
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          li {
            font-size: 18px;
            font-weight: bold;
            padding: 0px 16px;
            border: 1px solid rgba(black, 0.4);
            border-bottom: none;
            border-right: none;
            cursor: pointer;
            transition: .3s;
            &.now {
              background-color: $main-color;
              color: white;
              font-weight: normal;
            }
            &:hover {
              background-color: $main-color;
              color: white;
              font-weight: normal;
            }
            &:last-child {
              border-right: 1px solid rgba(black, 0.4);
            }
          }
        }
      }
    }
    .form-right {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0px 20px;
      input[id="uploadInput-setbg"] {
        display: none;
      }
      .notice {
        font-size: 20px;
        line-height: 28px;
        color: #999999;
      }
      #image-bg {
        width: 100%;
        height: 250px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;
      }
      .upload-btn {
        width: 100%;
        height: 250px;
        background-color: #eee;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
        i {
          font-size: 22px;
          margin-bottom: 10px;
        }
      }
    }
    label.secret--input {
      display: inline-block;
      font-size: 20px;
      font-weight: 600;
      color: #4A4A4A;
      text-align: right;
      width: 100px;
      white-space: nowrap;
      &.score {
        width: 20px;
      }
    }
    input.secret--input,select.secret--input,textarea.secret--input {
      width: calc(100% - 100px);
      border-radius: 3px;
      border: solid 1px #dddddddd;
      background-color: lighten(#eeeeee,5);
      margin-left: 10px;
      font-size: 18px;
      font-weight: 500;
      &:focus{outline:none}
    }

    input.secret--input,select.secret--input {
      height: 40px;
      padding: 0px 10px;
    }
    textarea.secret--input {
      padding: 10px;
    }
    .status-col {
      justify-content: flex-start;
      .status-box {
        margin-left: 15px;
      }
    }
    .history-block {
      .history-title {
        width: 110px;
      }
    }
  }
  .hangout-content {
    border: 1px solid #dddddd;
    overflow: hidden;
    margin-bottom: 25px;
    .chapter-section {
      width: 100%;
      background-color: transparent;
      padding: 20px 15px 20px 0px;
      label.chapterNum {
        min-width: 100px !important;
        padding-right: 8px;
      }
      &:nth-child(even) {
        background-color: #F4F4F4;
      }
      .input-box, .chapter-box {
        width: 100%;
        border: solid 1px #dddddd;
        background-color: #fbfbfb;
        border-radius: 3px;
        display: flex;
        input {
          width: 100%;
          border: none;
          margin: 0;
        }
        .delete-icon {
          color: #9B9B9B;
          font-size: 18px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;
          cursor: pointer;
        }
        button {
          padding: 0;
          width: 80px;
          transition: .3s;
          &:hover {
            background-color: lighten($main-color, 10);
          }
        }
      }
      .unit-section {
        .unit-information {
          border: 1px solid #dddddd;
          width: calc(100% - 100px);
          padding-left: 8px;
          display: flex;
          label.unitNum {
            margin: 14px 8px 8px 0px;
            width: auto;
          }
          .input-box {
            width: 100%;
            margin-left: 0px;
          }
          input {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
    .add-chapter, .add-Unit {
      width: 115px;
      height: 35px;
      padding: 0;
      margin-left: 10px;
    }
    .add-chapter {
      background-color: $main-color;
    }
  }
  .information-block {
    padding: 8px 8px;
    width: 100%;
  }
  .section-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 0px;
    width: 100%;
    padding: 8px 0px;
    &.line{
      border-bottom: 1px solid #dddddd;
    }
    .progressWrap {
      width: 200px;
      height: 20px;
      border: 2px solid $main-color;
      border-radius: 3px;
      overflow: hidden;
      .progress {
        width: 0px;
        height: 100%;
        background-color: $main-color;
        position: absolute;
        left: 0;
        top: 0;
      }
      .progress-text {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 13px;
        font-weight: bold;
        color: #A4B7C8;
        line-height: 15px;
        padding: 0 8px;
      }
    }
    input[id^='uploadVideo'] {
      display: none;
    }
    input[id^='uploadPDF'] {
      display: none;
    }
    .notice {
      width: calc(100% - 80px);
      text-align: left;
      align-items: center;
      font-size: 14px;
      line-height: 24px;
      margin-left: 8px;
    }
    button {
      height: 25px;
      width: 90px;
      font-size: 14px;
      color: #444444;
      background-color: white;
      padding: 0 8px;
      border: 1px solid #979797;
      border-radius: 2px;
      transition: .3s;
      white-space: nowrap;
      &:hover {
        background-color: #979797;
        color: white;
      }
    }
    .name-block, .length-block, .open-block{
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        color: $main-color;
        font-size: 25px;
        &.fa-file-pdf{
          color: $color-red;
        }
      }
      .length {
        width: 80px;
        border: 1px solid #dddddd;
        text-align: left;
        font-size: 18px;
        color: #9B9B9B;
        padding: 4px 12px;
      }
      .text {
        color: #4A4A4A;
        font-size: 18px;
        font-weight: bold;
        margin: 0px 12px;
        text-align-last: left;
        &.video-name{
          width: 150px;
          overflow : hidden;
          text-overflow : ellipsis;
          white-space : nowrap;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .length-block {
        display: none;
      }
    }
    .open-block {
      input[id^='openCheck'] {
        display: none;
        &:checked {
          ~ label.check {
            background-color: $color-blue;
            &:before{
              display: block;
            }
          }
        }
      }
      label {
        cursor: pointer;
        user-select: none;
      }
      label.check {
        width: 20px;
        height: 20px;
        border: 1px solid #979797;
        border-radius: 2px;
        &:before {
          content: '✔';
          color: white;
          font-size: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
        }
      }
    }
    .delete-icon {
      color: #9B9B9B;
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 8px;
      cursor: pointer;
    }
  }
  .activity-box{
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: .3s;
    flex-wrap: wrap;
    &.active {
      border: 1px solid #cccccc;
      padding: 15px;
      height: 173px;
      opacity: 1;
      overflow: inherit;
      @media screen and (max-width: $media-size-ipad) {
        height: 200px;
      }
      @media screen and (max-width: $media-size-phone) {
        height: 225px;
      }
    }
    .btn-group{
      margin-top: 10px;
      justify-content: flex-end;
    }
    .activity-time {
      justify-content: flex-start;
      @media screen and (max-width: $media-size-phone) {
        flex-direction: column;
        label {
          width: 100%;
          text-align: left;
        }
        .date-interval{
          width: 100%;;
        }
      }
    }
    @media screen and (max-width: $media-size-phone) {
      .price-box {
        label {
          text-align: left;
        }
      }
    }
    .date-interval {
      width: calc(100% - 100px);
      margin-left: 10px;
      display: flex;
      align-items: center;
      input {
        margin: 0;
        width: 100%;
      }
      .line {
        width: 20px;
        height: 1px;
        background-color: #4a4a4a;
      }
    }
  }
  .btn-group {
    margin-top: 40px;
    justify-content: center;
  }

  .form-groups .status-box > label {
    display: inline-block;
    width: 60px;
    height: 30px;
    border-radius: 20px;
    background-color: $toggle-checkbox-bgc-off;
    cursor: pointer;
    transition: all .3s;
    &:after {
      content: '';
      height: 29px;
      width: 29px;
      border-radius: 50%;
      box-shadow: 0px 0px 5px rgba(black,0.5);
      background-color: white;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: all .3s;
    }
  }
  .form-groups .status-box> input[type="checkbox"] {
    display: none;
    &:checked {
      + label {
        background-color: $toggle-checkbox-bgc-on;
        &:after{
          left: 100%;
          transform: translateX(-100%);
        }
      }
    }
  }
}

.add--resource {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  background-color: #fbfbfb;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-top: 10px;
  padding: 10px;

  .add--resource__title {
    width: 15%;
    text-align: center;
  }
  .add-resource__content {
    width: 85%;
  }

  .add--resource__units {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    color: #a4a4a4;
  }

  .add--resource__form,.add--resource__over {

    & .add--resource__actives {
      text-align: right;
      margin: 5px auto;
      & button {
        margin-left: 15px;
      }
    }

    & .add--resource__inputs {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      & > * {
        margin-bottom: 10px;
      }
    }
  }

  .add--resource__type {
    min-width: 15%;
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 5px 15px;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;

    &:hover {
      background-color: #eee;
      color: #000;
    }

    & svg {
      display: block;
      font-size: 2rem;
      margin-bottom: 3px;
      margin: auto;
    }
  }
}